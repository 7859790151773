import React from 'react';
import clsx from 'clsx';

import { XSText, SmallCardTitle } from '~utils/texts.react';

import './ProgressStatus.react.scss';

type ProgressStatusProps = {
  circleCSSClass?: string;
  containerCSSClass?: string;
  title?: string;
  onClick?: () => void;
};

const ProgressStatus = ({
  circleCSSClass,
  containerCSSClass,
  title,
  onClick,
}: ProgressStatusProps) => {
  return (
    <div
      className={clsx(containerCSSClass, 'progress-status__container', XSText())}
      onClick={onClick}
    >
      <div className={`progress-status__circle ${circleCSSClass || ''}`}></div>
      <span className={SmallCardTitle()}>{title}</span>
    </div>
  );
};

export default ProgressStatus;

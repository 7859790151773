import { Severity } from '~globalTypes';

import { Labels, StatisticCriteria, StatisticCriteriaValue } from './RepoChanges.types.react';

type statisticKeys = 'nNew' | 'nFixed' | 'nCritical' | 'nHigh' | 'nMedium';
type statisticObjectKeys = 'criteria' | 'criteriaValue';

export const REPO_CHANGE_STATISTICS_MAP: Record<
  statisticKeys,
  Record<statisticObjectKeys, StatisticCriteria | StatisticCriteriaValue | Severity>
> = {
  nNew: {
    criteria: StatisticCriteria.Change,
    criteriaValue: StatisticCriteriaValue.New,
  },
  nFixed: {
    criteria: StatisticCriteria.Change,
    criteriaValue: StatisticCriteriaValue.Fixed,
  },
  nCritical: {
    criteria: StatisticCriteria.Severity,
    criteriaValue: Severity.Critical,
  },
  nHigh: {
    criteria: StatisticCriteria.Severity,
    criteriaValue: Severity.High,
  },
  nMedium: {
    criteria: StatisticCriteria.Severity,
    criteriaValue: Severity.Medium,
  },
};

export const REPO_CHANGE_UI_MAP: Record<statisticKeys, string> = {
  nNew: Labels.NewActionItems,
  nFixed: Labels.FixedActionItems,
  nCritical: Labels.CriticalActionItems,
  nHigh: Labels.HighActionItems,
  nMedium: Labels.MediumActionItems,
};

export const Labels = {
  Completed: 'completed',
  Error: 'error',
  Overview: 'Overview',
  Repositories: 'Repositories',
  SaveSelectionsKey: 'repo-action-items',
} as const;

export const FINAL_SCAN_STATUSES = ['Completed', 'Error', 'Abandoned'];

export interface CodeScanActionItem {
  ID: number;
  ReportType: string;
  EventType: string;
  ResourceID: number;
  Resource: {
    ID: number;
    Name: string;
    Namespace: string;
    Kind: string;
    Filename: string;
    HelmName: string;
    ContainerName: string;
  };
  Title: string;
  Description: string;
  Remediation: string;
  Severity: number;
  SeverityLabel: string;
  Category: string;
  Resolution: string;
  AssigneeEmail: string;
  TicketProvider: string;
  TicketLink: string;
  TicketCreatedAt: string;
}

export interface AutomatedPRJobResponse {
  id: number;
  organization: string;
  status: string;
  codeScanActionItemIDsToFix: number[];
  link: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface ViewPRResponse {
  codeScanActionItems: CodeScanActionItem[];
  link: string;
  tryAgainEndpoint?: string;
}

export const STOP_POLLING_CREATED_PR = ['completed', 'error'] as const;

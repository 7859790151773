import React from 'react';

import './EmptyData.react.scss';

type EmptyDataProps = {
  label: string;
};

const EmptyData = ({ label }: EmptyDataProps) => (
  <div className="empty-data">
    <span>{label}</span>
  </div>
);

export default EmptyData;

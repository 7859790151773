export interface Commit {
  ID: number;
  title: string;
  commitHash: string;
  commitMessage: string;
  date: string;
  time: string;
  status?: string;
}

export const Labels = {
  LatestScans: 'Latest Scans',
} as const;

export interface IPRAcionItems {
  ID: number;
  Title: string;
  FileName: string;
  ResourceName: string;
}

export enum TableAccessors {
  Title = 'Title',
  FileName = 'FileName',
  ResourceName = 'ResourceName',
}

import React from 'react';
import { COLORS } from '~utils/styling';

const AccordionDropdown = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="14" height="8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M.741.838 7.13 6.792 13.518.838" stroke={COLORS.CORE.PRIMARY} />
    </svg>
  );
};

export default AccordionDropdown;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';

import { ChartType } from '~globalTypes';
import { RepositoryActionItem } from '../../ActionItemsTable/ActionItemsTable.types.react';

import { convertFiltersToURL } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import '../Charts.react.scss';

type ImprovedRepositoriesProps = {
  baseURL?: string;
  noData?: React.ReactNode;
  filters: Filters<RepositoryActionItem>;
  setFilter: (columnId: string, updater: any) => void;
};

const ImprovedRepositories = ({
  baseURL,
  filters,
  noData,
  setFilter,
}: ImprovedRepositoriesProps) => {
  const [improvedRepositories, setImprovedRepositories] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getImprovedRepositories();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (filters?.length) {
      getImprovedRepositories();
    }
  }, [filters]);

  const getImprovedRepositories = async () => {
    try {
      setLoaded(false);
      const params = new URLSearchParams('groupBy=repository');
      const URLParams = convertFiltersToURL(filters, params);
      const response = await sendRequest(
        'GET',
        `${baseURL}/ci/action-items/top?${URLParams}`,
        {},
        null,
      );
      const improvedRepositories = response.slice(0, 5).map((repository: ChartType) => repository);
      if (isMounted.current) {
        setImprovedRepositories(improvedRepositories);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_improved_repositories: ', e);
      toast.error(strings.repository.errorRetrievingRepos);
    }
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    setFilter('repositoryName', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const ClearButtonComponent = () => {
    const filtered = filters?.find((filter) => filter.id === 'repositoryName')?.value;
    if (chartClicked.current && filtered?.length) {
      return (
        <ClearButton
          isFiltered={filtered.length}
          isChartClicked={chartClicked.current}
          clearFilter={clearFilter}
        />
      );
    }
    return <></>;
  };

  const clearFilter = () => {
    setFilter('repositoryName', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButtonComponent />
      {improvedRepositories?.length ? (
        <div aria-label={strings.ariaLabels.topImpactedReposChart} className="repositories--charts">
          <BarChart
            data={improvedRepositories}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[COLORS.CHARTS.NAMESPACE[100], COLORS.CHARTS.NAMESPACE[200]]}
            layout="vertical"
            tooltipLabel={(d) => `${d.data.value}`}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default ImprovedRepositories;

import { useEffect, useRef } from 'react';
import { EventKeyMap, EventMap, EventCallback } from '~globalTypes';

export const useWindowEvents = (events: EventMap[]) => {
  const eventCallbacks = useRef<EventCallback>({});

  useEffect(() => {
    initEventListeners();
    return cleanUpEventListeners;
  }, []);

  const initEventListeners = () => {
    for (const event of events) {
      addEventCallback(event);
      window.addEventListener(event.name, eventCallbacks.current[event.name].callback);
    }
  };

  const addEventCallback = (event: EventMap) => {
    eventCallbacks.current[event.name] = {
      callback: getEventCallback(event.keyMap) as EventListener,
    };
  };

  const getEventCallback = (eventKeyMap: EventKeyMap[]) => (e: KeyboardEvent) => {
    for (const event of eventKeyMap) {
      if (e.key === event.key) {
        event.process();
      }
    }
  };

  const cleanUpEventListeners = () => {
    for (const event of events) {
      window.removeEventListener(event.name, eventCallbacks.current[event.name].callback);
    }
  };
};

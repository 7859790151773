import React, { SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CloseIcon from '~reactIcons/Close.icon.react';
import { Repository } from '~utils/global.types.react';

import {
  CodeScanActionItem,
  ViewPRResponse,
} from '~views/repositories/ReactRepository/ReactRepository.types.react';

import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';

import './ViewPRModal.react.scss';

type ViewPRModalProps = {
  isViewPRModalShown: boolean;
  repository: Repository;
  setIsViewPRModalShown: React.Dispatch<SetStateAction<boolean>>;
  viewPRResponse: ViewPRResponse;
};

const ViewPRModal = ({
  isViewPRModalShown,
  repository,
  setIsViewPRModalShown,
  viewPRResponse,
}: ViewPRModalProps) => {
  const hideViewPRModal = () => setIsViewPRModalShown(false);

  const openGithubLink = () => {
    if (!viewPRResponse?.link) {
      return;
    }

    window.open(viewPRResponse.link, '_blank');
  };

  return (
    <Modal onHide={hideViewPRModal} show={isViewPRModalShown} className="view-pr-modal">
      <Modal.Body className="custom-modal-body">
        <div className="custom-modal-title">
          <h3>{strings.repository.viewPRModalTitle}</h3>
          <div className="custom-close-icon">
            <CloseIcon onClick={hideViewPRModal} />
          </div>
        </div>
        <div>
          <p className={MediumBasicText({ weight: strings.textStyling.regular })}>
            {strings.repository.viewPRModalContent.replace('$repository_name', repository.Name)}
          </p>
          <div className="view-pr-modal__action-items">
            {viewPRResponse?.codeScanActionItems?.length ? (
              viewPRResponse.codeScanActionItems.map((actionItem: CodeScanActionItem) => (
                <div className="view-pr-modal__mt-10">
                  <span className="view-pr-modal__bold">{actionItem.Title}:</span>{' '}
                  {actionItem.Resource.Filename}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="create-pr-modal__modal-footer">
        <Button className="btn" variant="primary" onClick={openGithubLink}>
          {strings.repository.viewPullRequest}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewPRModal;

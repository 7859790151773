import React from 'react';

type BranchIconProps = {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
};

const BranchIcon = ({ width, height, fill, className, ...props }: BranchIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.2 0A2.2 2.2 0 0 0 0 2.18c0 .95.64 1.76 1.52 2.05v6.54A2.2 2.2 0 0 0 0 12.84C0 14.03.99 15 2.2 15s2.2-.97 2.2-2.16a2.2 2.2 0 0 0-1.53-2.07v-.1c0-.99.3-1.37.87-1.72a9.06 9.06 0 0 1 2.42-.8c.96-.23 2-.49 2.86-1.12a3.69 3.69 0 0 0 1.44-2.8A2.18 2.18 0 0 0 12 2.18a2.2 2.2 0 0 0-4.4 0c0 .95.64 1.75 1.5 2.04-.06.92-.4 1.38-.9 1.75a6.9 6.9 0 0 1-2.36.89c-.94.22-1.96.42-2.82.96l-.15.1v-3.7a2.18 2.18 0 0 0 1.52-2.04A2.19 2.19 0 0 0 2.2 0Zm0 1.34c.47 0 .84.37.84.84 0 .46-.37.82-.85.82s-.84-.36-.84-.82c0-.47.37-.84.84-.84Zm7.6 0c.48 0 .85.37.85.84 0 .46-.37.82-.85.82s-.84-.36-.84-.82c0-.47.37-.84.84-.84ZM2.2 12c.47 0 .84.37.84.84s-.37.82-.85.82-.84-.35-.84-.82.37-.84.84-.84Z"
        fill={fill}
      />
    </svg>
  );
};

export default BranchIcon;

import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import clsx from 'clsx';

import { PAGE_SIZE_OPTIONS } from '~utils/constants';
import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './ResultsDropdown.react.scss';

type ResultsDropdownProps = {
  pageSize: number;
  changePageSize: React.Dispatch<React.SetStateAction<number>> | ((pageSize: number) => void);
};

const ResultsDropdown = ({ pageSize, changePageSize }: ResultsDropdownProps) => {
  return (
    <div className={clsx('dropdown-section', XSText({ color: 'darkGray' }))}>
      <span>{strings.resultsPerPage}</span>
      <DropdownButton title={pageSize} size="sm" className="dropdown-section__selection">
        {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={() => {
              changePageSize(pageSizeOption);
            }}
          >
            {pageSizeOption}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default ResultsDropdown;

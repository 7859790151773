export interface RepoChange {
  Category: string;
  Description: string;
  EventType: string;
  ID: number;
  Remediation: string;
  ReportType: string;
  Resolution: string;
  Resource: {
    ContainerName: string;
    Filename: string;
    HelmName: string;
    ID: number;
    Kind: string;
    Name: string;
    Namespace: string;
  };
  ResourceID: number;
  Severity: number;
  SeverityLabel?: string;
  Change?: string;
  CreatedAt?: string;
  Title: string;
}

export interface RepoChangeStatistic {
  nNew?: number;
  nFixed?: number;
  nCritical?: number;
  nHigh?: number;
  nMedium?: number;
}

export enum StatisticCriteria {
  Severity = 'SeverityLabel',
  Change = 'Change',
}

export enum StatisticCriteriaValue {
  New = 'new',
  Fixed = 'fixed',
}

export const Labels = {
  ChangesTitle: 'Changes relative to the',
  Branch: 'branch',
  Main: 'main',
  Created: 'Created',
  Criteria: 'criteria',
  CriteriaValue: 'criteriaValue',
  CriticalActionItems: 'Critical Action Items',
  Fixed: 'fixed',
  FixedActionItems: 'Fixed Action Items',
  HighActionItems: 'High Action Items',
  MediumActionItems: 'Medium Action Items',
  New: 'new',
  NewActionItems: 'New Action Items',
  NoChanges: 'There are no changes.',
} as const;

import React, { useState, useEffect, SetStateAction, useRef } from 'react';
import { Badge } from 'react-bootstrap';
import { CardDeck } from 'react-bootstrap';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import { Labels, RepoChangeStatistic, RepoChange } from './RepoChanges.types.react';
import { REPO_CHANGE_STATISTICS_MAP, REPO_CHANGE_UI_MAP } from './RepoChanges.config.react';

import {
  Organization,
  RepoActionItemResponse,
  Repository,
  OptionType,
  CodeScan,
} from '~globalTypes';
import { getSeverityLabel, dateFromToday } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { strings } from '~utils/strings';
import { Title, XSText } from '~utils/texts.react';

import './RepoChanges.react.scss';

type RepoChangesProps = {
  organization: Organization;
  repository: Repository;
  selectedBranch: OptionType | undefined;
  selectedCommit?: CodeScan;
  topBranches?: OptionType[];
  setActiveActionItem?: React.Dispatch<SetStateAction<RepoActionItemResponse | undefined>>;
  fetchRepositoryActionItem: (
    repositoryID: number,
    actionItem: number,
    historicalResolutionOverride: string,
  ) => Promise<RepoActionItemResponse | null>;
};

const RepoChanges = ({
  organization,
  repository,
  selectedBranch,
  selectedCommit,
  topBranches,
  setActiveActionItem,
  fetchRepositoryActionItem,
}: RepoChangesProps) => {
  const [repoChanges, setRepoChanges] = useState<RepoChange[]>();
  const [repoChangeStatistics, setRepoChangeStatistics] = useState<RepoChangeStatistic | null>();
  const [createdAt, setCreatedAt] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const hasStatisticData = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  const baseURL = `/v0/organizations/${organization.Name}`;

  useEffect(() => {
    if ((repository && selectedBranch) || selectedCommit) {
      isMounted.current = true;
      initChanges();
    }
    return () => {
      isMounted.current = false;
    };
  }, [repository, selectedBranch, selectedCommit]);

  const initChanges = async () => {
    setLoaded(false);
    hasStatisticData.current = false;
    const latestActivities = selectedCommit ? [selectedCommit] : await getLatestActivities();
    if (!latestActivities?.length) return;
    const { ActionItems, FixedActionItems, CreatedAt } = latestActivities[0];
    const combinedChanges = combineChanges(ActionItems, FixedActionItems);
    if (isMounted.current) {
      setCreatedAt(CreatedAt);
      setRepoChanges(combinedChanges);
      setRepoChangeStatistics(getRepoChangeStatistics(combinedChanges));
      setLoaded(true);
    }
  };

  const getLatestActivities = async () => {
    if (!repository || !selectedBranch) return null;
    try {
      const params = new URLSearchParams(
        `repository=${repository.Name}&branch=${selectedBranch.value}`,
      );
      return await sendRequest(
        'GET',
        `${baseURL}/ci/repositories/scan-results?${params}`,
        {},
        null,
      );
    } catch (e) {
      if (e instanceof Error) {
        logger.logError('event_get_repo_changes', { message: e.message });
      }
    }
  };

  const combineChanges = (newActionItems: RepoChange[], fixedActionItems: RepoChange[]) => {
    const transformedNewActionItems = transformActionItems(newActionItems, Labels.New);
    const transformedFixedActionItems = transformActionItems(fixedActionItems, Labels.Fixed);
    return transformedNewActionItems.concat(transformedFixedActionItems);
  };

  const transformActionItems = (actionItems: RepoChange[], change: string) => {
    if (!actionItems?.length || !change) return [];
    return actionItems.map((actionItem: RepoChange) => ({
      ...actionItem,
      SeverityLabel: getSeverityLabel(actionItem.Severity),
      Change: change,
    }));
  };

  const getRepoChangeStatistics = (repoChanges: RepoChange[]) => {
    if (!repoChanges?.length) return null;
    const repoChangeStatistics: RepoChangeStatistic = {};
    let key: keyof typeof REPO_CHANGE_STATISTICS_MAP;
    for (key in REPO_CHANGE_STATISTICS_MAP) {
      const statisticNumber = getStatisticsNumber(
        repoChanges,
        REPO_CHANGE_STATISTICS_MAP[key][Labels.Criteria],
        REPO_CHANGE_STATISTICS_MAP[key][Labels.CriteriaValue],
      );
      if (statisticNumber !== 0) hasStatisticData.current = true;
      repoChangeStatistics[key] = statisticNumber;
    }
    return repoChangeStatistics;
  };

  const getStatisticsNumber = (
    repoChanges: RepoChange[],
    criteria: string,
    criteriaValue: string,
  ) => {
    if (!repoChanges.length || !criteria || !criteriaValue) return 0;
    let count = 0;
    for (const change of repoChanges) {
      if (
        change.Change !== strings.noTranslate.fixed &&
        change[criteria as keyof RepoChange] === criteriaValue
      ) {
        count++;
      } else if (
        criteriaValue === strings.noTranslate.fixed &&
        change.Change === strings.noTranslate.fixed
      ) {
        count++;
      }
    }
    return count;
  };

  const selectActionItem = (actionItem: RepoActionItemResponse) => async () => {
    const ai = await fetchRepositoryActionItem(
      repository.ID,
      actionItem.ID,
      actionItem.HistoricalResolution,
    );
    if (!ai) return;
    setActiveActionItem && setActiveActionItem(ai);
  };

  if (!repository || !selectedBranch || !organization) return <></>;

  return (
    <div>
      <CardDeck className="repository__changes-card">
        <Card>
          <Card.Header className="repository__changes-header">
            <h2>
              {Labels.ChangesTitle} {topBranches?.length ? topBranches[0].value : Labels.Main}{' '}
              {Labels.Branch}
            </h2>
          </Card.Header>
          <Card.Body className="repository__changes-body">
            {loaded ? (
              <>
                <div className="repository__changes-container">
                  {repoChanges?.length ? (
                    repoChanges.map((change) => (
                      <div className="repository__change-row" key={change.ID}>
                        <div className="repository__change-content">
                          <div className="repository__change-content-left">
                            <PillBadge text={getSeverityLabel(change.Severity)} />
                            <span
                              className="repository__change-title"
                              onClick={selectActionItem(change as RepoActionItemResponse)}
                            >
                              {change.Title}
                            </span>
                            <span className="repository__change-created-by">{Labels.Created}</span>
                            <span className="repository__change-created-at">
                              {dateFromToday(createdAt)}
                            </span>
                          </div>
                          <div className="repository__change-content-right">
                            <Badge
                              pill
                              className={clsx(
                                'repository__repo-change-badge',
                                XSText({ textTransform: strings.textStyling.capitalize }),
                                {
                                  'repository__repo-change-badge--fixed':
                                    change.Change === Labels.Fixed,
                                  'repository__repo-change-badge--new':
                                    change.Change !== Labels.Fixed,
                                },
                              )}
                            >
                              {change?.Change
                                ? `${change.Change[0].toUpperCase()}${change.Change.slice(1)}`
                                : ''}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="repository__changes-empty-container">
                      <span>{Labels.NoChanges}</span>
                    </div>
                  )}
                </div>
                {hasStatisticData.current ? (
                  <div className="repository__statistics">
                    {Object.keys(REPO_CHANGE_UI_MAP).map((key) => (
                      <div key={key} className="repository__statistic-item">
                        <div
                          className={clsx(
                            'repository__statistic-number',
                            Title({
                              size: strings.textStyling.xs,
                              color: strings.textStyling.dropdown,
                            }),
                          )}
                        >
                          {repoChangeStatistics
                            ? repoChangeStatistics[key as keyof typeof REPO_CHANGE_UI_MAP]
                            : 0}
                        </div>
                        <div
                          className={clsx(
                            'repository__statistic-label',
                            XSText({ weight: strings.textStyling.medium }),
                          )}
                        >
                          {REPO_CHANGE_UI_MAP[key as keyof typeof REPO_CHANGE_UI_MAP]}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className="repository__changes-loading-container">
                <LoadingSpinner />
              </div>
            )}
          </Card.Body>
        </Card>
      </CardDeck>
    </div>
  );
};

export default RepoChanges;

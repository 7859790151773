import React from 'react';
import { Button } from 'react-bootstrap';

import { strings } from '~utils/strings';

type ClearButtonProps = {
  isChartClicked?: boolean;
  isFiltered?: boolean;
  clearFilter?: () => void;
};

const ClearButton = ({ isChartClicked, isFiltered, clearFilter }: ClearButtonProps) => {
  if (!isChartClicked || !isFiltered) return <></>;

  return (
    <Button onClick={clearFilter} className="chart-clear" variant="white">
      {strings.general.Clear}
    </Button>
  );
};

export default ClearButton;

// eslint-disable-next-line
import PublicRepository from './PublicRepository.react';

export default {
  components: {
    'public-repository': PublicRepository,
  },
  methods: {
    store() {
      return this.$store;
    },
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
  },
};

import React, { useState, useEffect, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Commit, Labels } from './RepoCommits.types.react';
import { CodeScan, Organization, Repository, OptionType } from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import { CardTitlePrimary, SmallCardTitle, BasicText } from '~utils/texts.react';

import './RepoCommits.react.scss';

dayjs.extend(timezone);

type RepoCommitsProps = {
  organization: Organization;
  repository?: Repository;
  selectedBranch?: OptionType;
  setSelectedCommit?: React.Dispatch<SetStateAction<CodeScan | undefined>>;
  setIsMostRecentCodeScan: React.Dispatch<SetStateAction<boolean>>;
};

const RepoCommits = ({
  organization,
  repository,
  selectedBranch,
  setSelectedCommit,
  setIsMostRecentCodeScan,
}: RepoCommitsProps) => {
  const [codeScans, setCodeScans] = useState<CodeScan[]>([]);
  const [commits, setCommits] = useState<Commit[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const baseURL = `/v0/organizations/${organization.Name}`;
  const timeZone = getCurrentTimezone();

  useEffect(() => {
    initCommits();
  }, [organization, repository, selectedBranch]);

  const initCommits = async () => {
    try {
      const codeScans = await getCodeScans();
      initDefaultCommit(codeScans);
      const transformedCommits = transformCommits(codeScans);
      setCodeScans(codeScans);
      setCommits(transformedCommits);
    } catch (e) {
      logger.logError('error_repo_commits', e);
    }
  };

  const initDefaultCommit = (codeScans: CodeScan[]) => {
    if (!codeScans?.length) return;
    const searchParams = new URLSearchParams(window.location.search);
    const commitHash = searchParams.get('commitHash');
    if (commitHash) {
      const defaultCodeScan = codeScans.find(
        (codeScan: CodeScan) => codeScan.CommitHash === commitHash,
      );
      setSelectedCommit && setSelectedCommit(defaultCodeScan ? defaultCodeScan : codeScans[0]);
      setIsMostRecentCodeScan(
        defaultCodeScan &&
          (defaultCodeScan.CommitHash !== codeScans[0].CommitHash ||
            defaultCodeScan?.ID !== codeScans[0].ID)
          ? false
          : true,
      );
      return;
    }
    setIsMostRecentCodeScan(true);
    setSelectedCommit && setSelectedCommit(codeScans[0]);
  };

  const transformCommits = (codescans: CodeScan[]): Commit[] => {
    if (!codescans?.length) return [];
    return codescans.map((codeScan: CodeScan) => buildTransformCommitObj(codeScan));
  };

  const buildTransformCommitObj = (codeScan: CodeScan): Commit => {
    return {
      ID: codeScan.ID,
      title: `${codeScan?.Repository?.Name} ${codeScan?.BranchName}`,
      commitHash: codeScan.CommitHash,
      commitMessage: codeScan.CommitMessage,
      date: timeZone
        ? dayjs(codeScan?.CreatedAt).tz(timeZone).format('MM/DD/YYYY')
        : dayjs(codeScan?.CreatedAt).format('MM/DD/YYYY'),
      time: timeZone
        ? dayjs(codeScan?.CreatedAt).tz(timeZone).format('h:mm A')
        : dayjs(codeScan?.CreatedAt).format('h:mm A'),
      status:
        codeScan?.RepoScanJob?.status === 'not_processed'
          ? 'in_queue'
          : codeScan?.RepoScanJob?.status,
    };
  };

  const getCodeScans = async () => {
    if (!repository || !selectedBranch) return null;
    try {
      setLoaded(false);
      const commits = await sendRequest('GET', getRequestURL(), {}, null);
      setLoaded(true);
      return commits;
    } catch (e) {
      logger.logError('error_repo_commits', e);
    }
  };

  const getRequestURL = () => {
    if (!repository || !selectedBranch) return '';
    const params = new URLSearchParams(
      `repository=${repository.Name}&branch=${selectedBranch.value}`,
    );
    return `${baseURL}/ci/repositories/scan-results?${params}`;
  };

  const selectCommit = (commitId: number) => () => {
    if (!commitId) return;
    const selectedCommit = codeScans?.find((codeScan) => codeScan.ID === commitId);
    if (selectedCommit && codeScans) {
      setIsMostRecentCodeScan(
        selectedCommit.CommitHash !== codeScans[0]?.CommitHash ||
          selectedCommit.ID !== codeScans[0]?.ID
          ? false
          : true,
      );
      setSelectedCommit && setSelectedCommit(selectedCommit);
    }
  };

  return (
    <Card data-cy="repo-latest-commits" className="repo-latest-commits expanded">
      <Card.Body
        style={{ padding: strings.noTranslate.repoPadding }}
        className="repo-latest-commits__card-body"
      >
        <div>
          <Card.Header>
            <h2 className={CardTitlePrimary()}>{Labels.LatestScans}</h2>
          </Card.Header>
          {loaded ? (
            <div className="repo-latest-commits_body">
              <ul>
                {commits.map((commit: Commit, idx: number) => {
                  return (
                    <li
                      className={commit?.status || 'updated'}
                      key={idx}
                      title={commit?.status?.replace('_', ' ') || strings.repository.noScan}
                    >
                      <div className="commit">
                        <div
                          className={clsx(
                            'commit-type',
                            BasicText({ size: 'xxs', weight: strings.textStyling.thin }),
                          )}
                        >
                          <span
                            className={clsx(
                              'commit-type__bold',
                              SmallCardTitle({ color: strings.textStyling.default }),
                            )}
                          >
                            <span>{commit.title}:</span>{' '}
                            <Button
                              variant="link"
                              className="commit-hash"
                              onClick={selectCommit(commit.ID)}
                              title={commit.commitHash}
                            >
                              {commit.commitHash.slice(0, 7)}
                            </Button>
                          </span>
                        </div>
                        <div
                          className={BasicText({ size: 'xxs', weight: strings.textStyling.thin })}
                        >
                          {commit.date} at {commit.time}
                        </div>
                        <Button
                          variant="link"
                          className="commit-message"
                          onClick={selectCommit(commit.ID)}
                        >
                          {commit.commitMessage}
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div className="repo-latest-commits__loading-container">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default RepoCommits;

import React from 'react';
import { TableInstance } from 'react-table';
import { TableAccessors } from './CreatePRModal.types.react';

import { strings } from '~utils/strings';

export const getTableColumns = [
  {
    Header: strings.repository.title,
    accessor: TableAccessors.Title,
    className: 'no-overflow',
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return <span title={values.Title}>{values.Title}</span>;
    },
    minWidth: 346,
    width: 346,
  },
  {
    Header: strings.repository.fileName,
    accessor: TableAccessors.FileName,
    minWidth: 346,
    width: 346,
    className: 'no-overflow',
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return <span title={values.FileName}>{values.FileName}</span>;
    },
  },
  {
    Header: strings.repository.resourceName,
    accessor: TableAccessors.ResourceName,
    minWidth: 346,
    width: 346,
    className: 'no-overflow',
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return <span title={values.ResourceName}>{values.ResourceName}</span>;
    },
  },
];

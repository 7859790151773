import React, { useState, useEffect } from 'react';
import store from '~store/index';

// if just reading state, use useVuexStore hook; this triggers re-renders
// if we need to manipulate state, import store from '~store/index' into the file it's needed

// custom hook

function useVuexStore() {
  const [storeState, setStoreState] = useState(store.state);

  useEffect(() => {
    return store.subscribe((mutation, state) => {
      setStoreState({ ...state });
    });
  }, []);

  return storeState;
}

export default useVuexStore;

/*
function ExampleComponent() {
  const store = useVuexStore();

  return (
    <button
      onClick={() => {
        store.dispatch(UPDATE_CLUSTER, options.cluster);
      }}
    >
      Testing
    </button>
  );
}
*/

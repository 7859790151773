import React, { useEffect, useState, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Select, { SingleValue } from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import BranchIcon from '~reactComponents/Icons/BranchIcon.react';

import ProgressStatus from '~reactComponents/ProgressStatus/ProgressStatus.react';

import AccordionComponent from '~reactComponents/accordion/AccordionComponent.react';
import ActionItemsTable from '../components/ActionItemsTable/ActionItemsTable.react';
import CreatePRModal from '../components/CreatePRModal/CreatePRModal.react';
import ProcessPRModal from '../components/ProcessPRModal/ProcessPRModal.react';
import TryAgainPRModal from '../components/TryAgainPRModal/TryAgainPRModal.react';
import ViewPRModal from '../components/ViewPRModal/ViewPRModal.react';

import useVuexStore from '~hooks/useVuexStore';

import {
  AutomatedPRJobResponse,
  CodeScanActionItem,
  FINAL_SCAN_STATUSES,
  Labels,
  STOP_POLLING_CREATED_PR,
  ViewPRResponse,
} from './ReactRepository.types.react';
import { RepositoryActionItem } from '../components/ActionItemsTable/ActionItemsTable.types.react';
import { CodeScan, CommonLabels, IRoute, IRouter, OptionType, RepoActionItemResponse } from '~globalTypes';
import { MainBranches } from '~utils/constants';

import { COLORS } from '~utils/styling';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { handlePageChange } from '~utils/global.helpers.react';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { strings } from '~utils/strings';
import { SmallCardTitle } from '~utils/texts.react';

import './ReactRepository.react.scss';
import { vexillaShould } from '~utils/feature-flags';

type ReactRepositoryProps = {
  route: IRoute;
  router: () => IRouter;
  targetBranch?: string;
  isOwner: boolean;
};

const ReactRepository = ({ route, router, targetBranch, isOwner }: ReactRepositoryProps) => {
  const { organization, repositories, repository, cluster, user } = useVuexStore();
  const [selectedBranch, setSelectedBranch] = useState<OptionType>();
  const [activeActionItem, setActiveActionItem] = useState<RepoActionItemResponse | undefined>();
  const [updatedScanStatus, setUpdatedScanStatus] = useState<{
    title: string;
    cssClass: string;
  } | null>(null);
  const [jobLogs, setJobLogs] = useState<string | null>(null);
  const [selectedCommit, setSelectedCommit] = useState<CodeScan | undefined>();
  const [isForcePollingScanStatus, setIsForcePollingScanStatus] = useState<boolean>(false);
  const [isCreatePRModalShown, setIsCreatePRModalShown] = useState<boolean>(false);
  const [isProcessPRModalShown, setIsProcessPRModalShown] = useState<boolean>(false);
  const [isViewPRModalShown, setIsViewPRModalShown] = useState<boolean>(false);
  const [isTryAgainPRModalShown, setIsTryAgainPRModalShown] = useState<boolean>(false);
  const [codeScanActionItems, setCodeScanActionItems] = useState<CodeScanActionItem[]>([]);
  const [isLoadingCodeScanActionItems, setIsLoadingCodeScanActionItems] = useState<boolean>(true);
  const [viewPRResponse, setViewPRResponse] = useState<ViewPRResponse>();

  const topBranches = useRef<OptionType[]>([]);
  const logsRef = useRef<HTMLElement>();
  const pollingCreatedPRInterval = useRef<NodeJS.Timeout>();
  const repositoryActionItemPR = useRef<RepositoryActionItem | null>(null);

  const POLLING_CREATED_PR_INTERVAL_TIME = 10000;

  const baseURL = `/v0/organizations/${organization.Name}`;

  useEffect(() => {
    if (selectedCommit?.ID) {
      getCodeScanActionItems();
    }
    return () => {
      clearPollingCreatedPRInterval();
    };
  }, [selectedCommit]);

  const getCodeScanActionItems = async () => {
    if (!selectedCommit?.ID || !user) {
      return;
    }

    setIsLoadingCodeScanActionItems(true);

    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/ci/code-scans/${selectedCommit.ID}/automated-pull-request-jobs/eligible-fixes`,
        {},
        null,
      );

      const list = response?.codeScanActionItems || [];
      const codeScanActionItems = vexillaShould(strings.featureFlags.autoFixResourceRecommendations)
        ? list
        : list.filter((item: CodeScanActionItem) => item.ReportType !== 'prometheus-metrics');

      setCodeScanActionItems(codeScanActionItems);
      setIsLoadingCodeScanActionItems(false);

      if (route?.query?.openPullRequest) {
        setIsCreatePRModalShown(true);
      }
    } catch (e: unknown) {
      setIsLoadingCodeScanActionItems(false);
      logger.logError(`event_get_code_scan_items_${repository?.Name}_${selectedCommit.ID}`, e);
    }
  };

  const clearPollingCreatedPRInterval = () => {
    if (pollingCreatedPRInterval.current) {
      clearInterval(pollingCreatedPRInterval.current);
    }
  };

  const getBranchOptions = (branches: string[] | undefined): OptionType[] => {
    if (!branches?.length) return [];
    const branchOptions = branches.map((branch: string) => ({ value: branch, label: branch }));
    const sortedBranchOptions = sortBranchOptions(branchOptions);
    initSelectedBranch(sortedBranchOptions);
    return sortedBranchOptions;
  };

  const sortBranchOptions = (branchOptions: OptionType[]) => {
    if (!branchOptions?.length) return [];
    topBranches.current = findTopBranches(branchOptions);
    const remainingBranches = branchOptions.filter(
      (option) => !topBranches.current.find((branch) => branch.value === option.value),
    );
    return topBranches.current.concat(remainingBranches);
  };

  const findTopBranches = (branchOptions: OptionType[]) => {
    if (!branchOptions?.length) return [];
    const topBranches: OptionType[] = [];
    MainBranches.forEach((mainBranch: string) => {
      const branch = findBranch(branchOptions, mainBranch);
      if (branch) {
        topBranches.push(branch);
      }
    });
    return topBranches;
  };

  const findBranch = (branchOptions: OptionType[], branch: string) => {
    if (!branchOptions?.length || !branch) return null;
    return branchOptions.find((option) => option.value === branch);
  };

  const initSelectedBranch = (sortedBranchOptions: OptionType[]) => {
    if (!sortedBranchOptions?.length) return;
    if (targetBranch) {
      setSelectedBranchByParam(sortedBranchOptions, targetBranch);
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(strings.repository.branch)) {
      setSelectedBranchByParam(sortedBranchOptions, searchParams.get(strings.repository.branch));
      return;
    }
    setSelectedBranch(sortedBranchOptions[0]);
  };

  const setSelectedBranchByParam = (sortedBranchOptions: OptionType[], param: string | null) => {
    const branch = sortedBranchOptions.find((option) => option.value === param);
    setSelectedBranch(branch ? branch : sortedBranchOptions[0]);
  };

  const onBranchSelected = (e: SingleValue<OptionType>) => {
    setSelectedBranch(e as OptionType);
  };

  if (!repository || !organization) return <></>;

  const isMainBranch = useMemo(() => MainBranches.includes(selectedBranch?.value), [selectedBranch]);

  const branchOptions = useMemo(() => getBranchOptions(repository.Branches), [repository]);

  const nBranches = useMemo(() => repository.Branches?.length, [repository]);

  const org = organization.Name;

  const repoRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'repositories',
      label: Labels.Repositories,
      href: `/orgs/${org}/repositories`,
    },
    {
      id: 'repository',
      label: repository.Name,
      href: `/orgs/${org}/repositories/${encodeURIComponent(repository.Name)}`,
    },
    {
      id: 'last',
      label: Labels.Overview,
      href: ``,
      isActive: true,
    },
  ];

  const reRunAutoscan = async () => {
    try {
      const data = {
        repository: repository.Name,
        branch: selectedBranch?.value,
      };
      const response = await sendRequest('POST', `${baseURL}/ci/repo-scan-jobs`, { data }, null);
      if (response.status) {
        setIsForcePollingScanStatus(true);
      }
    } catch (e: unknown) {
      logger.logError(`event_re-run-autoscan_${repository?.Name}_${selectedBranch?.value}`, e);
    }
  };

  const goToLogs = () => {
    if (updatedScanStatus?.title && FINAL_SCAN_STATUSES.includes(updatedScanStatus.title)) {
      logsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const createPullRequest = () => {
    if (!codeScanActionItems?.length) {
      toast.error(strings.repository.noCodeScanActionItems);
      return;
    }

    setIsCreatePRModalShown(true);
  };

  const getCreatedPRStatus = async (jobId: number) => {
    if (!selectedCommit || !jobId) {
      return;
    }

    try {
      return await sendRequest(
        'GET',
        `${baseURL}/ci/code-scans/${selectedCommit.ID}/automated-pull-request-jobs/${jobId}`,
        {},
        null,
      );
    } catch (e: unknown) {
      logger.logError(`event_get_created_pr_status_${repository.Name}`, e);
    }
  };

  const getPRCodeScanActionItems = (codeScanActionItemIDsToFix: number[]) =>
    codeScanActionItems.filter((actionItem: CodeScanActionItem) => codeScanActionItemIDsToFix.includes(actionItem.ID));

  const buildViewPRResponse = (automatedPRJobResponse: AutomatedPRJobResponse) => ({
    link: automatedPRJobResponse?.link || '',
    codeScanActionItems: repositoryActionItemPR.current
      ? [
          {
            ID: repositoryActionItemPR.current.ID,
            Title: repositoryActionItemPR.current.title,
            Resource: { Filename: repositoryActionItemPR.current.resourceFilename },
          } as CodeScanActionItem,
        ]
      : getPRCodeScanActionItems(automatedPRJobResponse.codeScanActionItemIDsToFix),
    tryAgainEndpoint:
      selectedCommit &&
      `${baseURL}/ci/code-scans/${selectedCommit.ID}/automated-pull-request-jobs/${automatedPRJobResponse.id}`,
  });

  const handlePRStatus = (automatedPRJobResponse: AutomatedPRJobResponse) => {
    if (!automatedPRJobResponse?.status) {
      return;
    }

    setViewPRResponse(buildViewPRResponse(automatedPRJobResponse));

    switch (automatedPRJobResponse.status) {
      case Labels.Completed:
        setIsViewPRModalShown(true);
        break;
      case Labels.Error:
        setIsTryAgainPRModalShown(true);
        break;
      default:
        break;
    }
  };

  const handlePollingStatus = (automatedPRJobResponse: AutomatedPRJobResponse) => {
    setIsProcessPRModalShown(false);
    clearPollingCreatedPRInterval();
    handlePRStatus(automatedPRJobResponse);
  };

  const pollingCreatedPR = (automatedPRJobResponse: AutomatedPRJobResponse) => {
    if (!automatedPRJobResponse) {
      return;
    }

    pollingCreatedPRInterval.current = setInterval(async () => {
      const response = await getCreatedPRStatus(automatedPRJobResponse.id);

      if (response && STOP_POLLING_CREATED_PR.includes(response.status)) {
        handlePollingStatus(response);
        return;
      }
    }, POLLING_CREATED_PR_INTERVAL_TIME);
  };

  const onPullRequestCreated = async (codeScanActionItemIDsToFix: number[]) => {
    if (!codeScanActionItemIDsToFix?.length || !selectedCommit) {
      return;
    }

    try {
      const response = await sendRequest(
        'POST',
        `${baseURL}/ci/code-scans/${selectedCommit.ID}/automated-pull-request-jobs`,
        { data: { codeScanActionItemIDsToFix } },
        null,
      );

      if (response) {
        setIsProcessPRModalShown(true);
        pollingCreatedPR(response);
      }
    } catch (e: unknown) {
      logger.logError(`event_create_pull_requests_repository_${repository.Name}`, e);
      toast.error(strings.repository.failureToCreatePullRequest);
    }
  };

  const onPRCreatedFromTable = async (codeScanActionItemIDsToFix: number[]) => {
    if (!codeScanActionItemIDsToFix?.length) {
      return;
    }

    repositoryActionItemPR.current = null;

    onPullRequestCreated(codeScanActionItemIDsToFix);
  };

  const onPRCreatedFromContextMenu = async (actionItem: RepositoryActionItem) => {
    if (!actionItem) {
      return;
    }

    repositoryActionItemPR.current = actionItem;

    setIsCreatePRModalShown(true);
  };

  const showAutoscanButton = useMemo(() => {
    if (!user) return false;

    const params = new URLSearchParams(window.location.search).has('commitHash');
    return !params;
  }, [route, user]);

  return (
    <LayoutReact>
      <div className="repository-breadcrumbs">
        <Breadcrumbs
          data={repoRoutesList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
      </div>
      <div className="repository__container">
        {repository?.Branches?.length ? (
          <div className="repository__top-head">
            <div className="repository__top-head-left">
              <div className="repository__branches">
                <div className="repository__select-container">
                  <Select
                    classNamePrefix="repository__select"
                    aria-label={strings.repository.listOfBranches}
                    defaultValue={branchOptions[0]}
                    onChange={onBranchSelected}
                    options={branchOptions}
                    value={selectedBranch}
                  />
                  <BranchIcon width={12} height={15} fill={COLORS.CORE.DARK_GRAY} className="repository__branch-icon" />
                </div>
                <BranchIcon width={12} height={15} fill={COLORS.CORE.DARK_GRAY} />
                <span>
                  {nBranches} {nBranches && nBranches > 1 ? strings.repository.branches : strings.repository.branch}
                </span>
                {user && repository?.InstallationID && (
                  <div className="repository__create-pr">
                    {!isLoadingCodeScanActionItems && codeScanActionItems?.length > 0 && (
                      <Button
                        className={clsx(
                          'btn repository__create-pr-btn',
                          SmallCardTitle({ color: strings.textStyling.default }),
                        )}
                        onClick={createPullRequest}
                        type="button"
                      >
                        {strings.repository.createPullRequest}
                      </Button>
                    )}
                    {codeScanActionItems?.length ? (
                      <span>{`${codeScanActionItems.length} ${strings.repository.autoFixedIssues}`}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              {selectedCommit && (
                <div className="repository__selected-commit">
                  <span className="repository__commit-hash">
                    {selectedCommit.CommitHash ? `${selectedCommit.CommitHash.slice(0, 7)}: ` : ''}
                  </span>
                  <span className="repository__commit-message" title={selectedCommit.CommitMessage}>
                    {selectedCommit.CommitMessage?.length > 60
                      ? `${selectedCommit.CommitMessage.substring(0, 60)}${CommonLabels.Ellipsis}`
                      : selectedCommit.CommitMessage}
                  </span>
                </div>
              )}
            </div>
            <div className="repository__scan-status-container">
              {updatedScanStatus && (
                <ProgressStatus
                  circleCSSClass={`repository__scan-status-circle--${updatedScanStatus.cssClass}`}
                  containerCSSClass="repository__scan-status-circle-container"
                  onClick={goToLogs}
                  title={updatedScanStatus.title}
                />
              )}
              {repository.AutoScan && showAutoscanButton && (
                <Button
                  className="btn repository__re-run-btn"
                  variant="outline-secondary"
                  type="button"
                  disabled={updatedScanStatus && !FINAL_SCAN_STATUSES.includes(updatedScanStatus?.title) ? true : false}
                  onClick={reRunAutoscan}
                >
                  {strings.repository.reRunAutoscan}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ActionItemsTable
        activeActionItem={activeActionItem}
        baseURL={baseURL}
        cluster={cluster?.Name || ''}
        codeScanActionItems={codeScanActionItems}
        isForcePollingScanStatus={isForcePollingScanStatus}
        isMainBranch={isMainBranch}
        onPullRequestCreated={onPRCreatedFromContextMenu}
        organization={organization}
        repositories={repositories}
        repository={repository}
        route={route}
        router={router}
        savedSelectionsKey={Labels.SaveSelectionsKey}
        selectedBranch={selectedBranch}
        selectedCommit={selectedCommit}
        setActiveActionItem={setActiveActionItem}
        setIsForcePollingScanStatus={setIsForcePollingScanStatus}
        setJobLogs={setJobLogs}
        setSelectedCommit={setSelectedCommit}
        setUpdatedScanStatus={setUpdatedScanStatus}
        topBranches={topBranches.current}
        isOwner={isOwner}
      />
      {jobLogs && updatedScanStatus && FINAL_SCAN_STATUSES.includes(updatedScanStatus?.title) ? (
        <>
          <AccordionComponent
            title={strings.repository.autoScanLogs}
            headerClassNames="repository__accordion-header"
            bodyClassNames="repository__accordion-body"
          >
            <ReactMarkdown>{'```' + jobLogs + '```'}</ReactMarkdown>
          </AccordionComponent>
          <div ref={logsRef}></div>
        </>
      ) : (
        <></>
      )}
      {isCreatePRModalShown && (
        <CreatePRModal
          baseURL={baseURL}
          codeScanActionItems={codeScanActionItems}
          isCreatePRModalShown={isCreatePRModalShown}
          onCreatePRModalHidden={() => (repositoryActionItemPR.current = null)}
          onPullRequestCreated={onPRCreatedFromTable}
          repository={repository}
          repositoryActionItemPR={repositoryActionItemPR.current}
          selectedCommit={selectedCommit}
          setIsCreatePRModalShown={setIsCreatePRModalShown}
        />
      )}
      {isProcessPRModalShown && (
        <ProcessPRModal
          isProcessPRModalShown={isProcessPRModalShown}
          repository={repository}
          setIsProcessPRModalShown={setIsProcessPRModalShown}
        />
      )}
      {isViewPRModalShown && (
        <ViewPRModal
          isViewPRModalShown={isViewPRModalShown}
          repository={repository}
          setIsViewPRModalShown={setIsViewPRModalShown}
          viewPRResponse={viewPRResponse || ({} as ViewPRResponse)}
        />
      )}
      {isTryAgainPRModalShown && (
        <TryAgainPRModal
          isTryAgainPRModalShown={isTryAgainPRModalShown}
          onPullRequestCreated={onPullRequestCreated}
          repository={repository}
          setIsTryAgainPRModalShown={setIsTryAgainPRModalShown}
          viewPRResponse={viewPRResponse || ({} as ViewPRResponse)}
        />
      )}
      <Toaster />
    </LayoutReact>
  );
};

export default ReactRepository;

import React, { useState, useEffect, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';

import { RepositoryActionItem } from '../../ActionItemsTable/ActionItemsTable.types.react';
import { ChartType, CodeScan, OptionType, Repository } from '~globalTypes';

import { convertFiltersToURL } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import '../Charts.react.scss';

type TopIssuesProps = {
  baseURL: string;
  filters: Filters<RepositoryActionItem>;
  noData?: React.ReactNode;
  repository?: Repository;
  selectedBranch?: OptionType;
  selectedCommit?: CodeScan | undefined;
  setFilter: (columnId: string, updater: any) => void;
};

const TopIssues = ({
  baseURL,
  filters,
  noData,
  repository,
  selectedBranch,
  selectedCommit,
  setFilter,
}: TopIssuesProps) => {
  const [topIssues, setTopIssues] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getTopIssues();
    return () => {
      isMounted.current = false;
    };
  }, [selectedCommit]);

  useEffect(() => {
    if (filters?.length) {
      getTopIssues();
    }
  }, [filters]);

  const getTopIssues = async () => {
    try {
      setLoaded(false);
      const params = new URLSearchParams(getSearchParams());
      const URLParams = convertFiltersToURL(filters, params);
      const response = await sendRequest(
        'GET',
        `${baseURL}/ci/action-items/top?${URLParams}`,
        {},
        null,
      );
      const topIssues = response
        .slice(0, 5)
        .reverse()
        .map((issue: ChartType) => issue);
      if (isMounted.current) {
        setTopIssues(topIssues);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_top_issues: ', e);
      toast.error(strings.repository.errorRetrievingRepoTopIssues);
    }
  };

  const getSearchParams = () => {
    let searchParams = 'groupBy=title';
    if (repository && selectedBranch) {
      searchParams = `${searchParams}&repositoryName=${repository?.Name}&branch=${selectedBranch.value}`;
    }
    if (selectedCommit?.CommitHash) {
      searchParams = `${searchParams}&commitHash=${selectedCommit.CommitHash}`;
    }
    return searchParams;
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    setFilter('title', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const ClearButtonComponent = () => {
    const filtered = filters.find((filter) => filter.id === 'title')?.value;
    if (chartClicked.current && filtered?.length) {
      return (
        <ClearButton
          isFiltered={filtered.length}
          isChartClicked={chartClicked.current}
          clearFilter={clearFilter}
        />
      );
    }
    return <></>;
  };

  const clearFilter = () => {
    setFilter('title', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButtonComponent />
      {topIssues?.length ? (
        <div className="repositories--charts" aria-label={strings.ariaLabels.topRepoIssuesChart}>
          <BarChart
            data={topIssues}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[
              COLORS.CHARTS.PURPLE[100],
              COLORS.CHARTS.PURPLE[200],
              COLORS.CHARTS.PURPLE[300],
              COLORS.CHARTS.PURPLE[400],
              COLORS.CHARTS.PURPLE[500],
            ]}
            layout="horizontal"
            tooltipLabel={(d) => `${d.data.value}`}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default TopIssues;

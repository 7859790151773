import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import { PieData } from '~utils/global.types.react';

// data structure needed for graph: [{ id, label, value, color } - for each section on chart]

type PieChart = {
  data: PieData[];
  handleChartClick: () => void;
};

const PieChart = ({ data, handleChartClick }: PieChart) => {
  return (
    <ResponsivePie
      data={data || []}
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      colors={{ datum: 'data.color' }}
      onClick={handleChartClick}
      motionConfig={{
        mass: 1,
        tension: 429,
        friction: 1,
        clamp: true,
        precision: 0.01,
        velocity: 0,
      }}
      onMouseEnter={(_data: any, event: any) => {
        event.target.style.cursor = 'pointer';
      }}
    />
  );
};

export default PieChart;

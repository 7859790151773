import React from 'react';

import { strings } from '~utils/strings';

type ResultsProps = {
  size: number;
  index: number;
  numOfRows: number;
  className?: string;
};

const ShowingResults = ({ size, index, numOfRows, className }: ResultsProps) => {
  const firstNum = Math.max(size * index, 1);
  const secondNum = size * index + size > numOfRows ? numOfRows : size * index + size;

  return (
    <span className={`showing-results ${className}`}>
      {`${strings.general.Showing} ${firstNum}-${secondNum} ${
        strings.general.of
      } ${numOfRows.toLocaleString()} ${strings.general.results}`}
    </span>
  );
};

export default ShowingResults;

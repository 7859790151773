import React from 'react';

import { Item, ItemParams, Menu, Submenu } from 'react-contexify';

import { REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID } from '~reactHelpers';
import { EXPORT_ACTION_ITEMS_OPTIONS } from '../ActionItemsTable/ActionItemsTable.config.react';
import { RepositoryActionItem } from '../ActionItemsTable/ActionItemsTable.types.react';
import { Labels } from './ContextMenu.types.react';

import ExportIcon from '~assetIcons/export.svg';
import ResolveIcon from '~assetIcons/resolve.svg';
import DownloadIcon from '~reactComponents/Icons/Download.react';
import SnoozeIcon from '~reactComponents/Icons/Snooze.icon.react';

import PullRequest from '~assetIcons/pull-request.svg';

import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';
import { ACTION_ITEM_RESOLUTIONS, SNOOZE_OPTIONS } from '~utils/constants';
import { formatResolutionString } from '~views/organization/actionItems/ActionItems.helpers.react';

type ContextMenuProps = {
  onPullRequestCreated?: (actionItem: RepositoryActionItem) => void;
  showCreateTicketModal: (actionItem: RepositoryActionItem) => void;
  resolutionSetHandler: (actionItem: RepositoryActionItem, resolution: string) => void;
  snoozeSetHandler: (actionItem: RepositoryActionItem, snoozeOption: string) => void;
  resolveItem?: React.Dispatch<React.SetStateAction<number>>;
  handleExport: (action: string) => Promise<void>;
};

const ContextMenu = ({
  onPullRequestCreated,
  showCreateTicketModal,
  resolutionSetHandler,
  snoozeSetHandler,
  handleExport,
}: ContextMenuProps) => {
  const handleCreateTicketClick = ({ props: { actionItem } }: ItemParams) => {
    showCreateTicketModal(actionItem);
  };

  const handleCreatePullRequest = ({ props: { actionItem } }: ItemParams) => {
    if (!onPullRequestCreated) {
      return;
    }

    onPullRequestCreated(actionItem);
  };

  const resolutionSetHandlerWrapper = async (
    { props: { actionItem } }: ItemParams,
    resolution: string,
  ) => {
    resolutionSetHandler(actionItem, resolution);
  };

  const snoozeSetHandlerWrapper = async (
    { props: { actionItem } }: ItemParams,
    snoozeOption: string,
  ) => {
    snoozeSetHandler(actionItem, snoozeOption);
  };

  const isItemHidden = ({ props: { actionItem } }: any) => !actionItem?.canCreatePullRequest;

  return (
    <Menu id={REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>{Labels.AdditionalActions}</span>
      </Item>
      <Item onClick={handleCreateTicketClick}>
        <img
          className="ticket-icon"
          src={ExportIcon}
          style={{ paddingRight: '1rem' }}
          alt={strings.actionItemsPage.contextMenu.ticketIcon}
        />
        {strings.general.createTicket}
      </Item>
      {onPullRequestCreated && (
        <Item onClick={handleCreatePullRequest} hidden={isItemHidden}>
          <img
            className="pull-request-icon"
            src={PullRequest}
            style={{ paddingRight: '1rem' }}
            alt="pull request icon"
          />
          {strings.repository.createPullRequest}
        </Item>
      )}
      <Submenu
        label={
          <>
            <img
              className="resolve-icon"
              src={ResolveIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.resolveIcon}
            />
            {strings.general.Resolve}
          </>
        }
      >
        {ACTION_ITEM_RESOLUTIONS?.map((resolution, idx) => (
          <Item
            key={idx}
            closeOnClick={false}
            onClick={(items) => resolutionSetHandlerWrapper(items, resolution.value)}
          >
            {formatResolutionString(resolution.value)}
          </Item>
        ))}
      </Submenu>
      <Submenu
        label={
          <>
            <SnoozeIcon />
            {strings.general.Snooze}
          </>
        }
      >
        {SNOOZE_OPTIONS.map((snoozeOption) => {
          return (
            <Item
              key={snoozeOption}
              onClick={(items) => snoozeSetHandlerWrapper(items, snoozeOption)}
            >
              1 {snoozeOption}
            </Item>
          );
        })}
      </Submenu>
      <Submenu
        label={
          <>
            <DownloadIcon marginRight="1rem" fill={COLORS.CORE.PRIMARY} width="15" height="15" />
            {strings.general.Export}
          </>
        }
      >
        {EXPORT_ACTION_ITEMS_OPTIONS?.map((exportType, idx) => (
          <Item key={idx} closeOnClick={false} onClick={() => handleExport(exportType)}>
            {exportType}
          </Item>
        ))}
      </Submenu>
    </Menu>
  );
};

export default ContextMenu;

import * as React from 'react';
import { COLORS } from '~utils/styling';

type IconProps = {
  marginRight?: string;
  fill?: string;
};

const DownloadIcon = ({ marginRight, fill, ...props }: IconProps): JSX.Element => (
  <svg
    style={{ transform: 'rotate(180deg)', marginTop: '0.25rem', marginRight }}
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 14 14"
    role="download icon"
    {...props}
  >
    <path
      fill={fill || COLORS.CORE.LINK}
      fillRule="evenodd"
      d="m7.46 3.68 3.49 3.54c.61.61-.3 1.54-.92.92L7.97 6.08c-.17-.17-.3-.11-.3.13v7.14a.65.65 0 1 1-1.31 0V6.2c0-.24-.14-.3-.3-.13L4 8.14c-.62.62-1.54-.3-.93-.92l3.47-3.54a.64.64 0 0 1 .92 0ZM2.66 1.3a.65.65 0 1 1 0-1.31h8.71a.65.65 0 1 1 0 1.3H2.65Z"
      clipRule="evenodd"
    />
  </svg>
);

export default DownloadIcon;

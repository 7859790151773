import { toast } from 'react-hot-toast';

import { convertJsonToCsv, downloadCsv } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { RepositoryActionItem } from './ActionItemsTable.types.react';
import { EXPORT_FIELDS_MAP } from './ActionItemsTable.config.react';
import { RepoActionItemResponse, Repository } from '~globalTypes';
import { CodeScanActionItem } from '~views/repositories/ReactRepository/ReactRepository.types.react';

export const exportActionItems = async (
  searchParams: URLSearchParams,
  baseUrl: string,
  orgName: string,
  repositories: Repository[],
  repository: Repository,
): Promise<void> => {
  const response = await fetchActionItems(searchParams, baseUrl);
  // Return right away if a null response is returned.
  if (!response?.data?.ActionItems) {
    throw new Error();
  }
  await createCSVFile(transformActionItems(repositories!, repository!, response.data.ActionItems), orgName);
};

export const fetchActionItems = async (
  searchParams: URLSearchParams,
  baseUrl: string,
): Promise<{
  data: { ActionItems: RepoActionItemResponse[] };
  headers: { [key: string]: unknown };
}> => {
  try {
    const { data, headers } = await sendRequest(
      'GET',
      `${baseUrl}/ci/action-items?${searchParams.toString()}`,
      { returnHeaders: true },
      null,
    );
    return { data, headers };
  } catch (e) {
    toast.error('Unable to fetch Action Items. Try again.');
    logger.logError('error_fetching_action_items_export', e);
    return { data: { ActionItems: [] }, headers: {} };
  }
};

const canCreatePullRequest = (codeScanActionItems: CodeScanActionItem[], repoActionItem: RepositoryActionItem) => {
  if (!codeScanActionItems?.length || !repoActionItem) {
    return false;
  }

  return !!codeScanActionItems.find((actionItem: CodeScanActionItem) => actionItem.ID === repoActionItem.ID);
};

export const transformActionItems = (
  repositories: Repository[],
  repository: Repository,
  actionItems: RepoActionItemResponse[],
  codeScanActionItems: CodeScanActionItem[] = [],
) => {
  if (!actionItems?.length) return [];
  const transformedActionItems = [];
  for (const actionItem of actionItems) {
    const repoActionItem = getRepoActionItem(repositories, repository, actionItem);
    if (repoActionItem) {
      repoActionItem.canCreatePullRequest = canCreatePullRequest(codeScanActionItems, repoActionItem);
      transformedActionItems.push(repoActionItem);
    }
  }
  return transformedActionItems;
};

export const getRepoActionItem = (
  repositories: Repository[],
  repository: Repository,
  actionItem: RepoActionItemResponse,
): RepositoryActionItem | null => {
  if (!actionItem) return null;
  const actionItemRepository = actionItem.Repository;
  const actionItemResource = actionItem.Resource;
  const repositoryName = actionItemRepository?.Name ? actionItemRepository.Name : repository?.Name;
  const repositoryID = actionItemRepository?.ID ? actionItemRepository.ID : repository?.ID;
  const { firstScan, lastScan } = findFirstAndLastScan(repositories, repositoryName);
  return {
    ID: actionItem.ID,
    repositoryName: repositoryName,
    repositoryID: repositoryID,
    severityLabel: actionItem.SeverityLabel,
    title: actionItem.Title,
    reportType: actionItem.ReportType,
    eventType: actionItem.EventType,
    resourceContainer: actionItemResource?.ContainerName,
    resourceKind: actionItemResource?.Kind,
    resourceName: actionItemResource?.Name,
    resolution: actionItem.Resolution,
    historicalResolution: actionItem.HistoricalResolution,
    snoozeUntil: actionItem.SnoozeUntil,
    autoScan: actionItemRepository?.AutoScan,
    description: actionItem.Description,
    remediation: actionItem.Remediation,
    category: actionItem.Category,
    lastScan,
    firstScan,
    ticketLink: actionItem.TicketLink,
    ticketCreated: actionItem.TicketLink ? true : false,
    resourceFilename: actionItemResource.Filename,
  };
};

const findFirstAndLastScan = (repositories: Repository[], repositoryName: string) => {
  if (!repositoryName) return { firstScan: null, lastScan: null };
  const repository = repositories?.find((repo) => repo.Name === repositoryName);
  return { firstScan: repository?.FirstScan?.ScanDate, lastScan: repository?.LastScan?.ScanDate };
};

export const createCSVFile = async (data: RepositoryActionItem[], org: string) => {
  const formattedActionItems = formatAIsForExport(data);
  const csvData = await convertJsonToCsv(formattedActionItems, Object.values(EXPORT_FIELDS_MAP));
  const filename = actionItemFileName(org);
  downloadCsv(csvData, filename);
};

export const formatAIsForExport = (actionItems: RepositoryActionItem[]) =>
  actionItems.map((actionItem) => {
    const formattedAI: Record<string, unknown> = {};

    for (const field in EXPORT_FIELDS_MAP) {
      const formattedField = EXPORT_FIELDS_MAP[field];
      formattedAI[formattedField] = actionItem[field as keyof RepositoryActionItem];
    }
    return formattedAI;
  });

export const actionItemFileName = (orgName: string): string => `repositories_action-items_${orgName}`;

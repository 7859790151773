import { Modal, Button, Form } from 'react-bootstrap';
import { useTable, useFlexLayout, useRowSelect, usePagination, Row } from 'react-table';
import React, { useState, useMemo, SetStateAction, useEffect, useCallback } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import CloseIcon from '~reactIcons/Close.icon.react';

import { getTableColumns } from './CreatePRModal.config.react';
import { IPRAcionItems } from './CreatePRModal.types.react';

import { CodeScan, IndeterminateCheckboxProps, Repository } from '~utils/global.types.react';
import { CodeScanActionItem } from '~views/repositories/ReactRepository/ReactRepository.types.react';
import { RepositoryActionItem } from '../ActionItemsTable/ActionItemsTable.types.react';

import { strings } from '~utils/strings';

import './CreatePRModal.react.scss';

type CreatePRModalProps = {
  baseURL: string;
  codeScanActionItems: CodeScanActionItem[];
  isCreatePRModalShown: boolean;
  onCreatePRModalHidden: () => void;
  onPullRequestCreated: (codeScanActionItemIDsToFix: number[]) => void;
  repository: Repository;
  repositoryActionItemPR: RepositoryActionItem | null;
  selectedCommit: CodeScan | undefined;
  setIsCreatePRModalShown: React.Dispatch<SetStateAction<boolean>>;
};

const CreatePRModal = ({
  codeScanActionItems,
  isCreatePRModalShown,
  onCreatePRModalHidden,
  onPullRequestCreated,
  repositoryActionItemPR,
  selectedCommit,
  setIsCreatePRModalShown,
}: CreatePRModalProps) => {
  const [data, setData] = useState<IPRAcionItems[]>([]);
  const [filteredData, setFilteredData] = useState<IPRAcionItems[]>([]);

  const buildPRActionItem = (actionItem: CodeScanActionItem) => ({
    ID: actionItem?.ID,
    Title: actionItem?.Title || '',
    ResourceName: actionItem?.Resource?.Name || '',
    FileName: actionItem?.Resource?.Filename || '',
  });

  const transformCodeScanActionItems = () =>
    codeScanActionItems?.length
      ? codeScanActionItems.map((actionItem: CodeScanActionItem) => buildPRActionItem(actionItem))
      : [];

  useEffect(() => {
    if (codeScanActionItems?.length) {
      const prActionItems = transformCodeScanActionItems();
      setData(prActionItems);
      setFilteredData(prActionItems);
    } else {
      setData([]);
      setFilteredData([]);
    }
  }, [codeScanActionItems]);

  const columns = useMemo(() => getTableColumns, [data]);

  useEffect(() => {
    if (columns && filteredData && !repositoryActionItemPR) {
      toggleAllRowsSelected(true);
    }
  }, [columns, filteredData, repositoryActionItemPR]);

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef(null);
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (typeof resolvedRef === 'object' && resolvedRef.current) {
          resolvedRef.current.indeterminate = Boolean(indeterminate);
        }
      }, [resolvedRef, indeterminate]);

      return (
        <label>
          <input
            type="checkbox"
            ref={ref}
            {...rest}
            // Need to stop propagation otherwise row event is called again
            onClick={(e) => e.stopPropagation()}
          />
          <span />
        </label>
      );
    },
  );

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data: filteredData,
    },
    useFlexLayout,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          // important to not change
          id: strings.general.selection,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: Row<IPRAcionItems> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    },
  );

  const findSelectedRowId = useCallback(
    (repositoryActionItemPR: RepositoryActionItem | null) => {
      if (!repositoryActionItemPR) {
        return null;
      }

      for (const row of rows) {
        if (row.original.ID === repositoryActionItemPR.ID) {
          rows.splice(row.index, 1);
          rows.unshift(row);
          return row.id;
        }
      }

      return null;
    },
    [rows],
  );

  useEffect(() => {
    if (rows?.length && repositoryActionItemPR) {
      const selectedRowId = findSelectedRowId(repositoryActionItemPR);
      if (selectedRowId) {
        toggleRowSelected(selectedRowId, true);
      }
    }
  }, [rows, repositoryActionItemPR, findSelectedRowId]);

  const hideCreatePRModal = () => {
    onCreatePRModalHidden();
    setIsCreatePRModalShown(false);
  };

  const hasKeyword = useCallback((item: IPRAcionItems, keyword: string) => {
    if (!item) {
      return false;
    }

    keyword = keyword.toLowerCase();

    const fields = Object.keys(item).filter((field: string) => field !== 'ID');

    for (const field of fields) {
      if (item[field as keyof Omit<IPRAcionItems, 'ID'>].toLowerCase().includes(keyword)) {
        return true;
      }
    }

    return false;
  }, []);

  const transformData = (keyword: string) =>
    keyword ? data.filter((item: IPRAcionItems) => hasKeyword(item, keyword)) : data;

  const onSearched = (e: React.BaseSyntheticEvent) => {
    setFilteredData(transformData(e.target.value));
  };

  const createPullRequest = async () => {
    if (!selectedFlatRows?.length || !selectedCommit) {
      toast.error(strings.repository.pleaseSelectActionItems);
      return;
    }

    hideCreatePRModal();
    onPullRequestCreated(selectedFlatRows.map((row: Row<IPRAcionItems>) => row.original.ID));
  };

  return (
    <>
      <Modal onHide={hideCreatePRModal} show={isCreatePRModalShown} className="create-pr-modal">
        <Modal.Body className="custom-modal-body create-pr-modal__body">
          <div className="create-pr-modal__custom-modal-title">
            <h4>{strings.repository.createPRModalTitle}</h4>
            <div className="custom-close-icon">
              <CloseIcon onClick={hideCreatePRModal} />
            </div>
          </div>
          <div className="create-pr-modal__table-wrapper">
            <Form.Control
              type="search"
              placeholder={strings.repository.search}
              className="create-pr-modal__search"
              defaultValue={''}
              onChange={(e) => onSearched(e)}
              data-cy="search-text-input"
            />
            {data?.length ? (
              <>
                <table
                  className="create-pr-modal__table-container create-pr-modal__table"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className={
                              column.id === strings.general.selection ? 'select-header' : ''
                            }
                          >
                            <div>{column.render('Header')}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <div className="table-body">
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps([
                                    {
                                      className: `${cell.column?.className} ${
                                        cell.column?.id === strings.general.selection
                                          ? 'select-column'
                                          : ''
                                      }`,
                                      style: cell.column?.style,
                                    },
                                  ])}
                                >
                                  <div className="cell-container">{cell.render('Cell')}</div>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </div>
                </table>
              </>
            ) : (
              <div className="create-pr-modal__empty-container">
                <span>{strings.repository.createPRModalEmpty}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="create-pr-modal__modal-footer">
          <Button
            className="btn"
            variant="primary"
            onClick={createPullRequest}
            disabled={!selectedFlatRows?.length}
          >
            {strings.repository.createPullRequest}
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </>
  );
};

export default CreatePRModal;

import React, { SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';

import CloseIcon from '~reactIcons/Close.icon.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Repository } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { BasicText } from '~utils/texts.react';

import './ProcessPRModal.react.scss';

type ProcessPRModalProps = {
  isProcessPRModalShown: boolean;
  repository: Repository;
  setIsProcessPRModalShown: React.Dispatch<SetStateAction<boolean>>;
};

const ProcessPRModal = ({
  isProcessPRModalShown,
  repository,
  setIsProcessPRModalShown,
}: ProcessPRModalProps) => {
  const hideProcessPRModal = () => setIsProcessPRModalShown(false);

  return (
    <Modal onHide={hideProcessPRModal} show={isProcessPRModalShown} className="process-pr-modal">
      <Modal.Body className="custom-modal-body">
        <div className="custom-modal-title">
          <h3>{strings.repository.processPRModalTitle}</h3>
          <div className="custom-close-icon">
            <CloseIcon onClick={hideProcessPRModal} />
          </div>
        </div>
        <div>
          <p
            className={clsx(
              'process-pr-modal__content',
              BasicText({ size: strings.textStyling.md }),
            )}
          >
            {strings.repository.processPRModalContent.replace('$repository_name', repository.Name)}
          </p>
          <LoadingSpinner
            containerClassNames="process-pr-modal__loading-container"
            spinnerClassNames="process-pr-modal__loading"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProcessPRModal;

import React from 'react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

const MoveIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={strings.numbers.twentyfive}
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.42955 15.3631L3.06641 12M3.06641 12L6.42955 8.63685M3.06641 12H22.2664M18.9033 15.3631L22.2664 12M22.2664 12L18.9033 8.63685M9.30326 5.76314L12.6664 2.39999M12.6664 2.39999L16.0295 5.76314M12.6664 2.39999L12.6664 21.6M9.30326 18.2368L12.6664 21.6M12.6664 21.6L16.0295 18.2368"
        stroke={COLORS.CORE.DROPDOWN_FONT}
        stroke-width="1.5"
        stroke-linecap={strings.noTranslate.round}
        stroke-linejoin={strings.noTranslate.round}
      />
    </svg>
  );
};

export default MoveIcon;

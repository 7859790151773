import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import AccordionDropdown from '~reactComponents/Icons/AccordionDropdown.react';
import { Card } from '@fairwindsops/ui-components';

import './AccordionComponent.react.scss';

type AccordionComponentProps = {
  title?: string;
  children?: React.ReactNode;
  containerClassNames?: string;
  headerClassNames?: string;
  bodyClassNames?: string;
};

const AccordionComponent = ({
  title,
  children,
  containerClassNames,
  headerClassNames,
  bodyClassNames,
}: AccordionComponentProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsCollapsed((prevValue) => !prevValue);
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card className={containerClassNames}>
        <Card.Header className={headerClassNames}>
          <Accordion.Toggle
            as={Button}
            variant="white"
            eventKey="0"
            onClick={toggleAccordion}
            className="accordion-btn"
          >
            <div className="accordion-title">
              <span>{title}</span>
              <div className={`accordion-arrow ${isCollapsed ? 'accordion-collapsed' : ''}`}>
                <AccordionDropdown />
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={bodyClassNames}>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionComponent;

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import PieChart from '~reactComponents/charts/PieChart/PieChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';

import { RepositoryActionItem } from '../../ActionItemsTable/ActionItemsTable.types.react';
import { ChartType, CodeScan, CommonLabels, OptionType, Repository } from '~globalTypes';

import { convertFiltersToURL } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { colorScheme } from '~config/main.config';
import { strings } from '~utils/strings';

import '../Charts.react.scss';

type SeveritiesBreakdownProps = {
  baseURL: string;
  filters: Filters<RepositoryActionItem>;
  noData?: React.ReactNode;
  repository?: Repository;
  selectedBranch?: OptionType;
  selectedCommit?: CodeScan | undefined;
  setFilter: (columnId: string, updater: any) => void;
};

const SeveritiesBreakdown = ({
  baseURL,
  filters,
  noData,
  repository,
  selectedBranch,
  selectedCommit,
  setFilter,
}: SeveritiesBreakdownProps) => {
  const [severities, setSeverities] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>();

  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getSeverities();
    return () => {
      isMounted.current = false;
    };
  }, [selectedCommit]);

  useEffect(() => {
    if (filters?.length) {
      getSeverities();
    }
  }, [filters]);

  const getSeverities = async () => {
    try {
      setLoaded(false);
      const params = new URLSearchParams(getSearchParams());
      const URLParams = convertFiltersToURL(filters, params);
      const response = await sendRequest(
        'GET',
        `${baseURL}/ci/action-items/top?${URLParams}`,
        {},
        null,
      );
      const severities = response.slice(0, 5).map((severity: ChartType) => severity);
      if (isMounted.current) {
        setSeverities(severities);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_severities: ', e);
      toast.error(strings.repository.errorRetrievingSeverities);
    }
  };

  const getSearchParams = () => {
    let searchParams = 'groupBy=severity';
    if (repository && selectedBranch) {
      searchParams = `${searchParams}&repositoryName=${repository?.Name}&branch=${selectedBranch.value}`;
    }
    if (selectedCommit?.CommitHash) {
      searchParams = `${searchParams}&commitHash=${selectedCommit.CommitHash}`;
    }
    return searchParams;
  };

  const data = useMemo(() => {
    return severities?.map((severity) => ({
      label: severity.value.charAt(0).toUpperCase() + severity.value.slice(1),
      id: severity.value,
      color: (colorScheme as Record<string, string>)[severity.value],
      value: severity.count,
    }));
  }, [severities]);

  const handleChartClick = (data: any) => {
    setFilter('severityLabel', [{ value: data.id, label: data.id }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('severityLabel', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'severityLabel')?.value?.length}
      />
      {data?.length ? (
        <div
          aria-label={strings.ariaLabels.repoSeveritiesPieChart}
          className="repositories--charts"
        >
          <PieChart data={data || []} handleChartClick={handleChartClick} />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default SeveritiesBreakdown;

import React, { useState, useEffect } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { useContextMenu } from 'react-contexify';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';

import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';

import { Organization, RepoActionItemResponse, RepoActivity } from '~globalTypes';
import { escapeEvent, REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID } from '~reactHelpers';

import {
  DescriptionTopRow,
  RepositoryActionItem,
} from '../ActionItemsTable/ActionItemsTable.types.react';

import { TOP_ROW, CONTENT } from '../ActionItemsTable/ActionItemsTable.config.react';

import { useWindowEvents } from '~hooks/useWindowEvents.react';

import { strings } from '~utils/strings';
import { CardTitleDefault, MediumBasicText, BasicText } from '~utils/texts.react';

import './DescriptionPanel.react.scss';

type DescriptionPanelProps = {
  isMainBranch: boolean | undefined;
  latestActivities: RepoActivity[] | undefined;
  organization: Organization;
  selectedActionItem: RepositoryActionItem | null;
  setActiveActionItem?: React.Dispatch<React.SetStateAction<RepoActionItemResponse | undefined>>;
  setSelectedActionItem: React.Dispatch<React.SetStateAction<RepositoryActionItem | null>>;
};

const DescriptionPanel = ({
  isMainBranch,
  latestActivities,
  organization,
  selectedActionItem,
  setActiveActionItem,
  setSelectedActionItem,
}: DescriptionPanelProps) => {
  const [isSlided, setIsSlided] = useState<boolean>(false);

  const { show: showContextMenu } = useContextMenu({
    id: REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID,
  });

  useWindowEvents([
    escapeEvent(() => {
      closePanel();
    }),
  ]);

  useEffect(() => {
    setIsSlided(organization && selectedActionItem ? true : false);
  }, [organization, selectedActionItem]);

  const closePanel = () => {
    setIsSlided(false);
    setTimeout(() => {
      setSelectedActionItem(null);
      setActiveActionItem && setActiveActionItem(undefined);
    }, 1000);
  };

  return (
    <Card className={`repo-action-items--description ${isSlided ? '' : 'hidden'}`}>
      {selectedActionItem ? (
        <>
          <div className="repo-action-items--description--header">
            <div className="repo-action-items--description--header--container">
              <h2
                className={clsx(
                  'repo-action-items--description--header--container__title',
                  CardTitleDefault({ weight: strings.textStyling.regular }),
                )}
              >
                {selectedActionItem.title}
              </h2>
              <div className="badges" style={{ height: '3rem', alignItems: 'center' }}>
                <PillBadge text={selectedActionItem.severityLabel} />
                <Badge
                  pill
                  className={clsx(
                    'repo-action-items--description__category-badge',
                    BasicText({
                      size: 'sm',
                      color: strings.textStyling.link,
                      textTransform: strings.textStyling.capitalize,
                    }),
                  )}
                >
                  {selectedActionItem.category}
                </Badge>
                <button
                  className="additional-actions-btn"
                  onClick={(e) =>
                    showContextMenu({ event: e, props: { actionItem: selectedActionItem } })
                  }
                >
                  <img src={HorizontalEllipsis} alt="ellipsis icon" />
                </button>
              </div>
            </div>
            <CloseIcon onClick={closePanel} className="repo-action-items--description__close" />
          </div>
          <div className="body-content">
            <div className="body-row">
              {selectedActionItem ? (
                TOP_ROW({ selectedActionItem, isMainBranch, latestActivities }).map(
                  (headerItem: DescriptionTopRow, key: number) =>
                    !headerItem.isHidden && (
                      <div key={key}>
                        <h2
                          className={MediumBasicText({
                            bottomMargin: strings.textStyling.smBottom,
                            topMargin: strings.textStyling.md,
                          })}
                        >
                          {headerItem.title}
                        </h2>
                        <span className={BasicText()}>{headerItem.content}</span>
                      </div>
                    ),
                )
              ) : (
                <></>
              )}
            </div>
            {selectedActionItem ? (
              CONTENT(selectedActionItem).map(
                (contentItem, key) =>
                  contentItem?.content && (
                    <div key={key}>
                      <h2 className={MediumBasicText({ topMargin: strings.textStyling.md })}>
                        {contentItem.title}
                      </h2>
                      <div className="markdown">
                        <ReactMarkdown>{contentItem.content}</ReactMarkdown>
                      </div>
                    </div>
                  ),
              )
            ) : (
              <></>
            )}
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default DescriptionPanel;

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableInstance } from 'react-table';
import { useContextMenu } from 'react-contexify';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import ToggleColumnsVisibility from '../ToggleColumnsVisibility/ToggleColumnsVisibility.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import ShowingResults from '~reactComponents/ShowingResults/ShowingResults.react';
import ResultsDropdown from '~reactComponents/ResultsDropdown/ResultsDropdown.react';

import { RepositoryActionItem } from '../ActionItemsTable/ActionItemsTable.types.react';

import { REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID } from '~reactHelpers';

import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './TableCardHeader.react.scss';

dayjs.extend(LocalizedFormat);

type TableCardHeader = {
  globalSearch: string | null;
  isHidingColsSupported?: boolean;
  selectedActionItem: RepositoryActionItem | null;
  tableInstance: TableInstance<RepositoryActionItem>;
  totalRowCount: number;
  setGlobalSearch: (keywords: string | null) => any;
};

const TableCardHeader = ({
  globalSearch,
  isHidingColsSupported,
  selectedActionItem,
  tableInstance,
  totalRowCount,
  setGlobalSearch,
}: TableCardHeader) => {
  const {
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const { show: showContextMenu } = useContextMenu({
    id: REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID,
  });

  return (
    <div className="repo-action--items--header">
      <Row>
        <Col className="repo-action--items--header--section repo-action--items--header--section--top">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="repo-action--items--header--section__search"
              value={globalSearch || ''}
              onChange={(e) => setGlobalSearch(e.target.value || null)}
            />
          </Form>
          <div className="repo-action--items--header--section__actions-container">
            <button
              className={`additional-actions-btn ${selectedActionItem ? 'd-none' : ''}`}
              onClick={(e) =>
                showContextMenu({ event: e, props: { actionItem: selectedActionItem } })
              }
              data-cy="additional-actions-button"
            >
              <img src={HorizontalEllipsis} alt="ellipsis icon" />
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="repo-action--items--header--section">
          <ShowingResults
            size={pageSize}
            index={pageIndex}
            numOfRows={totalRowCount}
            className={XSText({ color: strings.textStyling.darkGray })}
          />
          {isHidingColsSupported && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <ToggleColumnsVisibility tableInstance={tableInstance} />
            </DndProvider>
          )}
        </Col>
        <Col
          className={`repo-action--items--header--section end ${
            selectedActionItem ? 'd-none' : ''
          }`}
        >
          <ResultsDropdown pageSize={pageSize} changePageSize={setPageSize} />
        </Col>
      </Row>
    </div>
  );
};

export default TableCardHeader;

import React from 'react';
import { Pagination } from 'react-bootstrap';
import { usePagination, DOTS } from '~hooks/usePagination.react';

export type PaginationProps = {
  pageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: undefined | number;
  currentPage: number;
};

const PaginationComponent = (props: PaginationProps): JSX.Element => {
  const { pageChange, totalCount, siblingCount = 2, currentPage } = props;

  const paginationRange: (number | string)[] | undefined = usePagination({
    currentPage,
    totalCount,
    siblingCount,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (paginationRange && (currentPage === undefined || paginationRange?.length < 2)) {
    return <></>;
  }

  const Range = () => (
    <>
      {paginationRange?.map((pageNumber: number | string, index) => {
        if (pageNumber === DOTS) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            onClick={() => pageChange((pageNumber as number) - 1)}
            active={Number(pageNumber) - 1 === currentPage}
            key={index}
          >
            {pageNumber}
          </Pagination.Item>
        );
      })}
    </>
  );

  return (
    <Pagination style={{ marginBottom: 0 }} data-cy="pagination-component">
      <Pagination.First onClick={() => pageChange(0)} />
      <Pagination.Prev onClick={() => pageChange(currentPage - 1)} />
      <Range />
      <Pagination.Next onClick={() => pageChange(currentPage + 1)} />
      <Pagination.Last onClick={() => pageChange(totalCount - 1)} />
    </Pagination>
  );
};

export default PaginationComponent;

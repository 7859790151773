import React from 'react';
import { OptionProps } from 'react-select';
import { OptionType } from '~globalTypes';

import './OptionComponent.react.scss';

const Option = (props: OptionProps<OptionType, true>) => {
  const { data, label, isSelected, selectOption } = props;

  return (
    <div
      title={label}
      className="select-checkbox"
      onClick={(event) => {
        event.preventDefault();
        selectOption(data);
      }}
    >
      <label>
        <input
          type="checkbox"
          id={`${label}-checkbox`}
          checked={isSelected}
          style={{ pointerEvents: 'none' }}
          onClick={(event) => event.preventDefault()}
          onChange={() => {}}
        />
        <span />
      </label>
      <span className="select-checkbox__label">{label}</span>
    </div>
  );
};

export default Option;

import React, { SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CloseIcon from '~reactIcons/Close.icon.react';
import { CodeScanActionItem, Repository } from '~utils/global.types.react';

import { ViewPRResponse } from '~views/repositories/ReactRepository/ReactRepository.types.react';
import { Labels } from './TryAgainPRModal.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';

import { strings } from '~utils/strings';
import { MediumBasicText, ThinTitle } from '~utils/texts.react';

import './TryAgainPRModal.react.scss';

type TryAgainPRModalProps = {
  isTryAgainPRModalShown: boolean;
  onPullRequestCreated: (codeScanActionItemIDsToFix: number[]) => void;
  repository: Repository;
  setIsTryAgainPRModalShown: React.Dispatch<SetStateAction<boolean>>;
  viewPRResponse: ViewPRResponse;
};

const TryAgainPRModal = ({
  isTryAgainPRModalShown,
  onPullRequestCreated,
  repository,
  setIsTryAgainPRModalShown,
  viewPRResponse,
}: TryAgainPRModalProps) => {
  const hideTryAgainPRModal = () => setIsTryAgainPRModalShown(false);

  const tryCreatePRAgain = async () => {
    if (!viewPRResponse.tryAgainEndpoint) {
      return;
    }

    try {
      const response = await sendRequest(
        'PATCH',
        viewPRResponse.tryAgainEndpoint,
        { data: { status: Labels.notProcessed }, showSuccessAlert: false, showErrorAlert: false },
        null,
      );

      if (response) {
        onPullRequestCreated(
          viewPRResponse.codeScanActionItems.map((actionItem: CodeScanActionItem) => actionItem.ID),
        );
        setIsTryAgainPRModalShown(false);
      }
    } catch (e) {
      logger.logError('error_re_create_pr', e);
    }
  };

  return (
    <Modal
      onHide={hideTryAgainPRModal}
      show={isTryAgainPRModalShown}
      className="try-again-pr-modal"
    >
      <Modal.Body className="custom-modal-body">
        <div className="custom-modal-title">
          <h3 className={ThinTitle({ bottomMargin: strings.textStyling.medBottom })}>
            {strings.repository.tryAgainPRModalTitle}
          </h3>
          <div className="custom-close-icon">
            <CloseIcon onClick={hideTryAgainPRModal} />
          </div>
        </div>
        <div>
          <p className={MediumBasicText({ weight: strings.textStyling.regular })}>
            {/* <p className="try-again-pr-modal__content"> */}
            {strings.repository.tryAgainPRModalContent.replace('$repository_name', repository.Name)}
          </p>
          <div className="try-again-pr-modal__action-items">
            {viewPRResponse?.codeScanActionItems?.length ? (
              viewPRResponse.codeScanActionItems.map((actionItem: CodeScanActionItem) => (
                <div className="try-again-pr-modal__mt-10">
                  <span className="try-again-pr-modal__bold">{actionItem.Title}:</span>{' '}
                  {actionItem.Resource.Filename}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="try-again-pr-modal__modal-footer">
        <Button className="btn" variant="primary" onClick={tryCreatePRAgain}>
          {strings.repository.tryAgain}
        </Button>
        <Button className="try-again-pr-modal__outline-btn" onClick={hideTryAgainPRModal}>
          {strings.general.Cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TryAgainPRModal;
